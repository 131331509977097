import { useI18n } from 'vue-i18n';
import { documentStatuses } from '~/models/document-statuses';

export const getDocumentStatuses = () => {
  const { t } = useI18n();
  return documentStatuses.map((item) => {
    return {
      label: t(item.toLowerCase()),
      value: item.toUpperCase()
    }
  })
}